/**
 * Names of system component.
 *
 * Mostly used in environment variable REACT_APP_COMPONENT_NAME and in related configuration logic.
 *
 * IMPORTANT: There are places in the project which use these literal values without referencing the constants.
 * So if the values ever need to change, look for all usage.
 * For example:
 * - package.json scripts
 * - setupProxy.js (no ES6 allowed there)
 */
export const ComponentName = {

  // The CMS web app (front and backend)
  CMS: 'cms',

  // The student web app (front and backend)
  STUDENT: 'student',

  // The client web app (front and backend)
  CLIENT: 'client',

  // The senior web app (front and backend)
  SENIOR: 'senior',

  // The job background service
  JOB_SERVICE: 'job_service',
}