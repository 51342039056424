// Polyfill imports have to be on first line
import 'react-app-polyfill/stable'
import React from 'react'
import { ComponentName } from 'careibu-constants/component/ComponentName.js'
import { createRoot } from 'react-dom/client'

function importAppRoot() {
    switch (import.meta.env.REACT_APP_COMPONENT_NAME) {
        case ComponentName.STUDENT:
            return import('./app/student/App.jsx')
        case ComponentName.CLIENT:
            return import('./app/client/App.jsx')
        case ComponentName.SENIOR:
            return import('./app/senior/App.jsx')
        default:
            throw new Error('Unknown REACT_APP_COMPONENT_NAME: ' + process.env.REACT_APP_COMPONENT_NAME)
    }
}

importAppRoot().then(({App}) => {
    const container = document.getElementById('root')
    const root = createRoot(container)
    root.render(<App />)
})


